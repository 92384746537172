
import React from "react";
import { Row, Col, Card } from '@themesberg/react-bootstrap';
import EnvIndicator from './EnvIndicator';

const Footer = () => {
  return (
    <div>
      <footer className="footer">
        <Row>
          <Col xs={12} lg={12} className="">
            <p className="mb-0 text-center">
            This is an
            <Card.Link href="https://www.umassmed.edu/about/official/" target="_blank" className="text-blue text-decoration-none fw-normal"> official page </Card.Link> of the UMass Chan Medical School
            </p>
            <p className="mb-0 text-center ">
            55 Lake Avenue North, Worcester, MA 01545
            </p>
            <p className="mb-0 text-center">
            <strong>Questions or Comments?</strong> Email: <a href="mailto:innovation@umassmed.edu">Innovation@umassmed.edu</a>
            </p>
            <p className="text-center">
            <a href="https://www.umassmed.edu/about/privacy-statement/" target="blank" >Privacy Statement</a></p>
            <p className="mb-0 text-center">
            <EnvIndicator position="bottom"></EnvIndicator>
            </p>
          </Col>
        </Row>
      </footer>
    </div>
  );
};

export default Footer;
